import { DecimalPipe } from '@angular/common';
import { Injectable, Pipe, PipeTransform } from '@angular/core';

import { ExgTranslateService } from '../../services/common/exg-translate.service';

@Injectable({
    providedIn: 'root'
})
@Pipe({ name: 'exgNumber' })
export class ExgDecimalPipe implements PipeTransform {
    private thousand = 1000;
    private million = 1000000;
    private billion = 1000000000;

    constructor(private translate: ExgTranslateService) { }

    public transform(value: any, digits?: string, braceNegative?: boolean, emptyDisplayValue?: string, groupThousand?: boolean, groupDigits?: string): string {
        if (!!+value || value === '0' || value === 0) {
            let val = +value;
            if (braceNegative && val < 0) {
                val *= -1;
            }

            if (groupThousand && ((value >= this.thousand) || (value <= -this.thousand))) {
                if ((value < this.million) || (value < 0 && value > -this.million)) {
                    const thousands = val / this.thousand;
                    const thousandsResult = new DecimalPipe(this.translate.getCurrentLang()).transform(+(thousands.toFixed(2)), groupDigits).replace(',', '.');
                    return `${thousandsResult} ${this.translate.instant('thousand_short')}`;
                }

                if ((value < this.billion) || (value < 0 && value > -this.billion)) {
                    const millions = val / this.million;
                    const millionsResult = new DecimalPipe(this.translate.getCurrentLang()).transform(+(millions.toFixed(2)), groupDigits).replace(',', '.');
                    return `${millionsResult} ${this.translate.instant('million_short')}`;
                }

                const billions = val / this.billion;
                const billionsResult = new DecimalPipe(this.translate.getCurrentLang()).transform(+(billions.toFixed(2)), groupDigits).replace(',', '.');
                return `${billionsResult} ${this.translate.instant('billion_short')}`;
            }

            let result = new DecimalPipe(this.translate.getCurrentLang()).transform(val, digits).replace(',', '.');
            if (braceNegative && +value < 0) {
                result = `(${result})`;
            }

            return result;
        }
        return emptyDisplayValue || '';
    }
}
