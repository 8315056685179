import { Injectable } from '@angular/core';

import { BaseSingletonService } from '../../burns-ui-framework/shared/services/common/base-singleton.service';
import { HttpService } from '../../burns-ui-framework/shared/services/common/http.service';

import { DictionaryEntryListItem } from '../models/business/dictionaries/dictionary-entry-list-item.model';
import { EmployeeListItem } from '../models/business/employee/employee-list-item.model';
import { VehicleListItem } from '../models/business/shifts/vehicle-list-item.model';
import { WellListItem } from '../models/business/shifts/well-list-item.model';

import { String } from '../../burns-ui-framework/shared/utils/string';

@Injectable({
    providedIn: 'root'
})
export class ShiftResourcesService extends BaseSingletonService {

    private settings: {
        service: {
            getDrillingRigs: string;
            getVehicles: string;
            getWells: string;
        }
    };

    constructor(private http: HttpService) {
        super('ShiftResourcesService');
        this.settings = {
            service: {
                getDrillingRigs: '/drilling/v1/shift-resources/drilling-rigs?workingAreaUid={0}',
                getVehicles: '/drilling/v1/shift-resources/vehicles?workingAreaUid={0}',
                getWells: '/drilling/v1/shift-resources/wells?workingAreaUid={0}&date={1}&shiftType={2}'
            }
        };
    }

    public async getDrillingRigs(workingAreaUid: string): Promise<DictionaryEntryListItem[]> {
        return this.http.get<{ list: DictionaryEntryListItem[] }>(String.format(this.settings.service.getDrillingRigs, workingAreaUid))
            .then(res => res.list);
    }

    public async getVehicles(workingAreaUid: string): Promise<VehicleListItem[]> {
        return this.http.get<{ list: VehicleListItem[] }>(String.format(this.settings.service.getVehicles, workingAreaUid))
            .then(res => res.list);
    }

    public async getWells(workingAreaUid: string, date: number, shiftType: number): Promise<WellListItem[]> {
        return this.http.get<{ list: WellListItem[] }>(String.format(this.settings.service.getWells, workingAreaUid, date, shiftType))
            .then(res => res.list);
    }
}
