import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';

import { ShiftResourcesService } from '../../services/shift-resources.service';

import { ErrorObject } from '../../../burns-ui-framework/shared/models/common/error-object.model';
import { WellListItem } from '../../models/business/shifts/well-list-item.model';

import { ShiftWellsAction, ShiftWellsFailAction, ShiftWellsResetAction, ShiftWellsSuccessAction } from './shift-wells.actions';

export interface ShiftWellsStateModel {
    entity: WellListItem[];
    pending: boolean;
    error: ErrorObject;
}

@State<ShiftWellsStateModel>({
    name: 'ShiftWells',
    defaults: { pending: false, entity: [], error: null }
})
@Injectable()
export class ShiftWellsState {
    constructor(private shiftResourcesService: ShiftResourcesService) {}

    @Action(ShiftWellsAction) wellsGet(ctx: StateContext<ShiftWellsStateModel>, action: ShiftWellsAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: true, error: null });

        return this.shiftResourcesService.getWells(action.payload.workingAreaUid, action.payload.date, action.payload.shiftType)
            .then(resp => setTimeout(() => ctx.dispatch(new ShiftWellsSuccessAction(resp)), 0))
            .catch(err => setTimeout(() => ctx.dispatch(new ShiftWellsFailAction(err)), 0));
    }

    @Action(ShiftWellsSuccessAction) wellsGetSuccess(ctx: StateContext<ShiftWellsStateModel>, action: ShiftWellsSuccessAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: action.payload, error: null });
    }

    @Action(ShiftWellsFailAction) wellsGetFail(ctx: StateContext<ShiftWellsStateModel>, action: ShiftWellsFailAction) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, error: action.payload });
    }

    @Action(ShiftWellsResetAction) wellsGetReset(ctx: StateContext<ShiftWellsStateModel>) {
        const state = ctx.getState();
        ctx.setState({ ...state, pending: false, entity: [], error: null });
    }
}
